import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { ReactComponent as Logo } from '../../../assets/econce_logo.svg';
import classNames from 'classnames';
import NumberFormatCustom from '../../common/NumberFormatCustom';
import { getCurrencySymbol } from '../../../lib/helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.neutral[50],
        padding: 16,
        justifyContent: 'center',
        flexDirection: 'row',
        maxWidth: '100vw',
        overflow: 'hidden',
        borderBottom: `1px solid ${theme.palette.neutral[100]}`,
        boxShadow: 'none',
        margin: '0',
        padding: '0',
        fontFamily: 'Helvetica, sans-serif',
    },
    h1: {
        fontFamily: 'Syne, sans-serif',
    },
    h2: {
        fontFamily: 'Syne, sans-serif',
    },
    a4: {
        width: '210mm',
        height: '297mm',
        padding: '10mm',
        paddingTop: 0,
        margin: '10% auto',

        '@media print': {
            paddingTop: '3rem',
        },
    },
    container: {
        width: '100%',
        height: '100%',
    },
    line: {
        margin: '2rem 0',
        height: '1px',
        background: '#e6e6e6',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dates: {
        textAlign: 'right',
    },
    logo: {
        marginTop: '25px',
    },
    headRow: {
        fontWeight: '400',
        textAlign: 'left',
        background: '#e6e6e6',
    },
    body: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginTop: '2rem',
        marginBottom: '2rem',
    },
    companyName: {
        marginBottom: 4,
    },
    td: {
        padding: '12px 8px',
    },
    rightAlign: {
        textAlign: 'right',
    },
    total: {
        background: '#cecece',
    },
    table: {
        margin: '2rem 0',
        minWidth: '100%',
    },
    thMain: {
        fontWeight: 'bold',
    },
    centerAlign: {
        textAlign: 'center',
    },
}));

export default function InvoiceTemplate({ invoice }) {
    const classes = useStyles();

    return (
        <div className={classes.a4}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <div>
                        <h1>
                            Invoice/Faktura:
                            <br />
                            {invoice.internalId}
                        </h1>
                    </div>
                    <div className={classes.dates}>
                        <div>Dated/Datum fakture: {invoice.dated}</div>
                        <div>Due Date/Datum dospeća: {invoice.dueDate}</div>
                        <div>
                            Trading place/Mesto prometa: {invoice.tradingPlace}
                        </div>
                    </div>
                </div>
                <div className={classes.line}></div>
                <div className={classes.body}>
                    <div className={classes.left}>
                        <Typography variant="h6">From/Od:</Typography>
                        <h2 className={classes.companyName}>
                            {invoice.company.name}
                        </h2>
                        <div>{invoice.company.fullname}</div>
                        <div>{invoice.company.address1}</div>
                        <div>
                            {invoice.company.zip} {invoice.company.city}
                        </div>
                        <div>{invoice.company.country}</div>
                        <div>{invoice.company.email}</div>
                        <div>VAT NO/PIB: {invoice.company.vat}</div>
                        <div>SWIFT: {invoice.company.billing.swift}</div>
                        <div>IBAN: {invoice.company.billing.iban}</div>
                    </div>
                    <div className={classes.right}>
                        <Typography variant="h6">Bill to/Komitent:</Typography>
                        <h2 className={classes.companyName}>
                            {invoice.client.name}
                        </h2>
                        <div>{invoice.client.address1}</div>
                        <div>{invoice.client.address2}</div>
                        <div>{invoice.client.city}</div>
                        <div>
                            {invoice.client.state} {invoice.client.zip}
                        </div>
                        <div>{invoice.client.country}</div>
                    </div>
                </div>
                <table className={classes.table}>
                    <thead>
                        <tr className={classes.headRow}>
                            <td className={classes.td}>
                                <Typography variant="h6">
                                    <span className={classes.thMain}>ITEM</span>
                                    <br />
                                    (USLUGA)
                                </Typography>
                            </td>
                            <td className={classes.td}>
                                <Typography variant="h6">
                                    <span className={classes.thMain}>UNIT</span>
                                    <br />
                                    (JED.MERE)
                                </Typography>
                            </td>
                            <td className={classes.td}>
                                <Typography variant="h6">
                                    <span className={classes.thMain}>QTY</span>
                                    <br />
                                    (KOLIČINA)
                                </Typography>
                            </td>
                            <td className={classes.td}>
                                <Typography variant="h6">
                                    <span className={classes.thMain}>
                                        PRICE
                                    </span>
                                    <br />
                                    (CENA)
                                </Typography>
                            </td>
                            <td className={classes.td}>
                                <Typography variant="h6">
                                    <span className={classes.thMain}>
                                        TOTAL
                                    </span>
                                    <br />
                                    (TOTAL)
                                </Typography>
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                        {invoice.items.map((item, index) => (
                            <tr key={index}>
                                <td className={classes.td}>
                                    {item.description}
                                </td>
                                <td className={classes.td}>{item.unit}</td>
                                <td
                                    className={classNames(
                                        classes.td,
                                        classes.rightAlign
                                    )}
                                >
                                    {item.qty}
                                </td>
                                <td
                                    className={classNames(
                                        classes.td,
                                        classes.rightAlign
                                    )}
                                >
                                    <NumberFormatCustom
                                        value={item.price}
                                        displayType={'text'}
                                        onChange={(v) => v}
                                        prefix={getCurrencySymbol(
                                            invoice.currency
                                        )}
                                    />
                                </td>
                                <td
                                    className={classNames(
                                        classes.td,
                                        classes.rightAlign
                                    )}
                                >
                                    <NumberFormatCustom
                                        value={item.total}
                                        displayType={'text'}
                                        onChange={(v) => v}
                                        prefix={getCurrencySymbol(
                                            invoice.currency
                                        )}
                                    />
                                </td>
                            </tr>
                        ))}

                        <tr className={classes.headRow}>
                            <td className={classes.td} colSpan="3">
                                <h2>Due Amount / Ukupno (USD)</h2>
                            </td>
                            <td
                                className={classNames(
                                    classes.td,
                                    classes.total
                                )}
                                colSpan="2"
                            >
                                <h2 className={classes.rightAlign}>
                                    <NumberFormatCustom
                                        value={invoice.total}
                                        displayType={'text'}
                                        onChange={(v) => v}
                                        prefix={getCurrencySymbol(
                                            invoice.currency
                                        )}
                                    />
                                </h2>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={classes.centerAlign}>
                    Document is valid without stamp and signature / Faktura je
                    važeća bez pečata i potpisa
                </div>
            </div>
        </div>
    );
}
