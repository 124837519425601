import { Box, Divider, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ClientPicker from './ClientPicker';
import CompanyPicker from './CompanyPicker';
import InvoiceItems from './InvoiceItems';
import InvoiceMeta from './InvoiceMeta';
import { DateTime } from 'luxon';
import { createInvoice } from '../../../api/firebase';
import InvoiceSidebar from './InvoiceSidebar';
import { useParams } from 'react-router';
import useGetters from '../../../hooks/useGetters';

export default function CreateInvoice() {
    const { id } = useParams();
    const history = useHistory();
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [invoiceMeta, setInvoiceMeta] = useState({
        dated: DateTime.now(),
        dueDate: DateTime.now().plus({ days: 21 }),
        currency: 'USD',
    });
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const { data, loading, error } = useGetters('invoice', id);

    const { invalidateData } = useGetters('invoices');

    useEffect(() => {
        if (id) {
            console.log('Loading Invoice ID: ', id);
        }
    }, [id]);

    useEffect(() => {
        setTotal(recalcTotal());
    }, [items]);

    useEffect(() => {
        if (selectedClient) {
            let newMeta = { ...invoiceMeta, tradingPlace: selectedClient.city };
            setInvoiceMeta(newMeta);
        }
    }, [selectedClient]);

    const recalcTotal = () => {
        let newTotal = 0;
        items.forEach((item) => {
            newTotal += Number(item.total);
        });
        return newTotal;
    };

    const handleSubmit = async () => {
        console.log('Invoice submit data: ', {
            selectedClient,
            selectedCompany,
            items,
            total,
            invoiceMeta,
        });

        // return;

        try {
            const doc = await createInvoice({
                ...invoiceMeta,
                dated: invoiceMeta.dated.toLocaleString(DateTime.DATE_MED), // need to transform to be able to save
                dueDate: invoiceMeta.dueDate.toLocaleString(DateTime.DATE_MED), // need to transform to be able to save
                datedTimestamp: invoiceMeta.dated.toMillis(), // for sorting and tech
                client: selectedClient,
                company: selectedCompany,
                companyId: selectedCompany.id,
                items,
                total,
            });

            if (doc) {
                console.log('Created invoice: ', doc.id);
                invalidateData();
                history.push(`/invoice/${doc.id}`);
            }
        } catch (error) {
            console.error(error);
        }
    };
    // TODO: Submit - handle !invoiceDate and !dueDate - set to today and today + 21

    return (
        <Box id="createInvoice" display="flex" minHeight="100%">
            <Box flexGrow={1} pr={4}>
                <Box
                    mb={4}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Box mb={1}>
                        <Typography variant="h4">
                            Invoice {invoiceMeta.internalId}
                        </Typography>
                    </Box>
                    <InvoiceMeta data={invoiceMeta} setData={setInvoiceMeta} />
                </Box>
                <Box mt={4} mb={4}>
                    <Divider />
                </Box>
                <Box display="flex">
                    <Box flexGrow={1} flexShrink={0} width="50%">
                        <Typography variant="h6">From</Typography>
                        <CompanyPicker
                            selected={selectedCompany}
                            setSelected={setSelectedCompany}
                        />
                    </Box>
                    <Box flexGrow={1} flexShrink={0} width="50%">
                        <Typography variant="h6">Bill to</Typography>
                        <ClientPicker
                            selected={selectedClient}
                            setSelected={setSelectedClient}
                        />
                    </Box>
                </Box>
                <Box mt={4} mb={4}>
                    <Divider />
                </Box>
                <Box>
                    <InvoiceItems
                        items={items}
                        setItems={setItems}
                        currency={invoiceMeta.currency || 'USD'}
                    />
                </Box>
            </Box>
            <Box width={320} m={-2}>
                <InvoiceSidebar
                    total={total}
                    onSubmit={handleSubmit}
                    invoiceMeta={invoiceMeta}
                    setInvoiceMeta={setInvoiceMeta}
                />
            </Box>
        </Box>
    );
}
