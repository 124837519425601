// useGetters
// IF field in store and loaded -> return
// ELSE fetch and return

import { useState, useEffect } from 'react';
import useAppState from '../store/useAppState';
import {
    fetchClients,
    fetchCompanies,
    fetchInvoice,
    fetchInvoices,
} from '../api/firebase';
import actions from '../store/actions';

const fieldFetchMapping = {
    clients: fetchClients,
    companies: fetchCompanies,
    invoices: fetchInvoices,
    invoice: fetchInvoice,
};

const fieldActionMapping = {
    clients: actions.SET_CLIENTS,
    companies: actions.SET_COMPANIES,
    invoices: actions.SET_INVOICES,
    invoice: actions.SET_INVOICE,
    invalidate: actions.INVALIDATE,
};

const useGetters = (field, id) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const { state, dispatch } = useAppState();

    useEffect(() => {
        // 101
        // this is run every time state[field] changes
        console.log(`[useGetters / ${field}]: re-render`);

        if (state[field] && state[field].single && state[field].loaded) {
            console.log(
                `[useGetters / ${field}]: found simple data in store: `,
                state[field]
            );
            setData(state[field].data);
            setLoading(false);
            return;
        }

        if (state[field] && state[field].loaded) {
            console.log(
                `[useGetters / ${field}]: found array data in store: `,
                state[field]
            );
            setData(state[field].array);
            setLoading(false);
            return;
        }

        fetchData(field, id);
    }, [state[field]]);

    const fetchData = async (field, id) => {
        console.log(
            `[useGetters / ${field}]: no data in store. Starting fetch...`
        );
        const fetchFn = fieldFetchMapping[field];
        const action = fieldActionMapping[field];

        setLoading(true);

        try {
            const fetchedData = await fetchFn(id);
            console.log(
                `[useGetters / ${field}]: ${field} fetched success: `,
                fetchedData
            );

            setError(false); // reset error to false, if there was an error previously

            // setting data in store should force re-render
            // re-render should find data and setLoading(false)
            // search and see: 101
            dispatch({
                type: action,
                payload: fetchedData,
            });
        } catch (error) {
            console.error(`[useGetters / ${field}]: `, error);
            setError('Error or missing permissions.');
            setLoading(false); // even if error occured, set loading to false, bacuse API loading actually finished
        }
    };

    const invalidateData = () => {
        dispatch({
            type: actions.INVALIDATE,
            payload: field,
        });
    };

    return { data, loading, error, invalidateData };
};

export default useGetters;
